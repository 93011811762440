import { jwtDecode } from "jwt-decode";
import { useSelector } from "ducks/store";

// ==============================|| AUTH HOOKS ||============================== //

const useAuth = () => {
  const user = useSelector((state) => state.user);

  try {
    if (!user || !user.accessToken) return null;
    const jwtDecoded: any = jwtDecode(user.accessToken);
    return jwtDecoded;
  } catch (e) {
    return null;
  }
};

export default useAuth;
