export type User = {
  accessToken: string;
};

export const initialUser = {
  accessToken: ''
};

export type UserAuthReq = {
  username: string;
  password: string;
};
