import { Reducer } from 'redux';

type Reducers = {
    [key: string]: Reducer;
};

type Sagas = GeneratorFunction[];

type ReducerModule = {
    default: Reducer;
};

type SagaModule = {
    default: GeneratorFunction;
};

const snakeToCamel = (str: string) => str.replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''));

const getModuleName = (path: string): string => {
    const name = path.replace(/\.\/(.+)\/.+\.ts/, '$1');
    return snakeToCamel(name);
};

export const loadReducers = (): Reducers => {
    const reducers: Reducers = {};
    const context = require.context('./', true, /\.\/.+\/slice\.ts$/);
    context.keys().forEach((key: string) => {
        const name = getModuleName(key);
        if (reducers[name]) {
            throw new Error(`Store name already existing: ${key}`);
        }
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const loadedModule = <ReducerModule>context(key);

        if (!loadedModule.default) {
            throw new Error(`Reducer must be exported with default statement: ${key}`);
        }
        reducers[name] = loadedModule.default;
    });

    return reducers;
};

export const loadSagas = (): Sagas => {
    const sagas: Sagas = [];
    const context = require.context('./', true, /\.\/.+\/sagas.ts$/);
    context.keys().forEach((key: any) => {
        const loadedModule = context(key) as SagaModule;
        if (!loadedModule.default) {
            throw new Error(`Saga must be exported with default statement: ${key}`);
        }
        sagas.push(loadedModule.default);
    });

    return sagas;
};
