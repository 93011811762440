// import GuestGuard from 'utils/route-guard/GuestGuard';
import useAuth from "hooks/useAuth";
import Login from "pages/login";
import GuestGuard from "./route-guard/GuessGuard";
import MinimalLayout from "layouts/minimal-layout";
import NotFoundPage from "pages/404";

const NotFoundRoutes = () => {
  const authData = useAuth();

  return {
    path: "*",
    element: authData ? (
      <NotFoundPage />
    ) : (
      <GuestGuard>
        <MinimalLayout>
          <Login />
        </MinimalLayout>
      </GuestGuard>
    ),
  };
};

export default NotFoundRoutes;
