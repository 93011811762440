import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch as useAppDispatch, useSelector as useAppSelector, TypedUseSelectorHook } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import { persistReducer, persistStore } from 'redux-persist';
import { DefaultRootStateProps } from './types';
import rootSaga from './sagas';

// project imports
import rootReducer from './reducers';
import storage from 'redux-persist/lib/storage';

// ==============================|| REDUX - MAIN STORE ||============================== //

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['user']
};

const rootPersistedReducer = persistReducer(persistConfig, combineReducers(rootReducer));

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
    reducer: rootPersistedReducer,
    middleware: (getDefaultMiddleware) => [...getDefaultMiddleware({ thunk: false, serializableCheck: false }), sagaMiddleware]
});

sagaMiddleware.run(rootSaga);

// const persister = persistStore(store).pause();

const persister = persistStore(store);

// export type RootState = ReturnType<typeof rootReducer>;
export type RootState = DefaultRootStateProps;

export type AppDispatch = typeof store.dispatch;

const { dispatch } = store;

const useDispatch = () => useAppDispatch<AppDispatch>();
const useSelector: TypedUseSelectorHook<RootState> = useAppSelector;

export { store, persister, dispatch, useSelector, useDispatch };

