// project imports

import Login from "pages/login";
import GuestGuard from "./route-guard/GuessGuard";
import MinimalLayout from "layouts/minimal-layout";

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: "/",
  element: (
    <GuestGuard>
      <MinimalLayout />
    </GuestGuard>
  ),
  children: [
    {
      path: "/login",
      element: <Login />,
    },
  ],
};

export default LoginRoutes;
