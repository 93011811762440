import { ReactHookForm, useForm } from "components/forms/ReactHookForm";
import NESInput from "components/nes-input";
import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema } from "./validation";
import { DefaultValues, initDefaultValues } from "./init-data";
import { useDispatch } from "ducks/store";
import { loginUserRequest } from "ducks/user/slice";

const Login = () => {
  const dispatch = useDispatch();

  const methods = useForm<DefaultValues>({
    defaultValues: initDefaultValues,
    resolver: yupResolver(validationSchema),
    reValidateMode: "onChange",
  });

  const { handleSubmit } = methods;

  const onSubmit = (values: DefaultValues) => {
    dispatch(loginUserRequest(values));
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <section className="nes-container with-title is-dark w-fit">
        <h3 className="title">Login</h3>
        <ReactHookForm {...methods}>
          <div className="nes-field">
            <label htmlFor="name_field">Username</label>
            <NESInput
              name="username"
              type="text"
              placeholder="Input your username"
            />
          </div>
          <div className="nes-field">
            <label htmlFor="name_field">Password</label>
            <NESInput
              name="password"
              type="password"
              placeholder="Input your password"
            />
          </div>
          <button
            type="button"
            className="nes-btn"
            onClick={handleSubmit(onSubmit)}
          >
            Login
          </button>
        </ReactHookForm>
      </section>
    </div>
  );
};

export default Login;
