import React from "react";

const NotFoundPage = () => {
  return (
    <div className="flex justify-center items-center h-screen">
      <span className="nes-text is-error">404 Not Found</span>
    </div>
  );
};

export default NotFoundPage;
