import { ConfigProps } from "types";

export enum AppEnvironment {
  Mockup,
  Development,
  Test,
  Stage,
  Production,
}

const DEVBaseURL = "https://bongdahomnay.7utc.com";
const TESTBaseURL = "";
const STGBaseURL = "";
const PROBaseURL = "";

export const baseURL = (environment: AppEnvironment) => {
  switch (environment) {
    case AppEnvironment.Development:
      return DEVBaseURL;
    case AppEnvironment.Test:
      return TESTBaseURL;
    case AppEnvironment.Stage:
      return STGBaseURL;
    case AppEnvironment.Production:
      return PROBaseURL;
    default:
      return "";
  }
};

export const Environment: AppEnvironment = AppEnvironment.Development;

const config: ConfigProps = {
  fontFamily: "Bai Jamjuree",
  borderRadius: 8,
  outlinedFilled: true,
  navType: "light",
  presetColor: "default",
  locale: "vi",
};

export default config;
