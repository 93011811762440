import { useRoutes } from "react-router-dom";
import homeRouters from "./HomeRoutes";
import loginRoutes from "./LoginRoutes";
import NotFoundRoutes from "./404Routes";

const Routers = () => {
  return useRoutes([homeRouters, loginRoutes, NotFoundRoutes()]);
};

export default Routers;
