import { put, call, takeLatest } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import { getType } from "@reduxjs/toolkit";
import axiosClient, { AxiosResponse } from "ducks/axios-instance";
import {
  getDiceHistoryRequest,
  getDiceHistorySuccess,
  getDiceHistoryFailure,
} from "./slice";
import { DiceHistoryState } from "./types";

export function* GetDiceHistoryRequest(
  action: ReturnType<typeof getDiceHistoryRequest>
): SagaIterator {
  try {
    const response: AxiosResponse<DiceHistoryState> = yield call(
      axiosClient.get,
      "king2fun/dice/hitory",
      { params: action.payload }
    );
    yield put(getDiceHistorySuccess(response.data));
  } catch (err: any) {
    yield put(getDiceHistoryFailure(err));
  }
}

export default function* defaultGetStationerySaga() {
  yield takeLatest(getType(getDiceHistoryRequest), GetDiceHistoryRequest);
}
