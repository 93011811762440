/* eslint-disable react/destructuring-assignment */
import { ReactNode } from "react";
import {
  FieldValues,
  FormProvider,
  UseFormReturn,
  useForm,
} from "react-hook-form";

export type FormProviderType<T extends FieldValues> = {
  children: ReactNode;
} & UseFormReturn<T>;

const ReactHookForm = <T extends FieldValues = FieldValues>(
  methods: FormProviderType<T>
) => <FormProvider {...methods}>{methods.children}</FormProvider>;

export { ReactHookForm, useForm };
