import axios, { AxiosResponse, AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios';
import { store } from 'ducks/store';
import { addRequestId as addRequestIdToStore, deleteRequestId as deleteRequestIdFromStore } from 'ducks/loading/slice';
import { resStatusHandler, errStatusHandler } from 'common/status-handle';
import { Environment, baseURL } from 'config';

declare module 'axios' {
    interface AxiosRequestConfig {
        requestIds?: string[];
        showLoading?: boolean;
        token?: string;
    }
}

export const loadingHandler = () => {
    const addRequestId = (config: InternalAxiosRequestConfig<AxiosRequestConfig>) => {
        const requestId = config.url || '';
        const showLoading = config.showLoading || true;
        config.requestIds = config.requestIds || [];
        config.requestIds.push(requestId);
        if (config.headers) {
            config.headers.Authorization! = `Bearer ${store.getState().user.accessToken}`;
        }
        store.dispatch(addRequestIdToStore({ requestId, showLoading }));

        return config;
    };

    const deleteRequestId = (res: AxiosResponse) => {
        if (res.config.requestIds) {
            for (const requestId of res.config.requestIds) {
                store.dispatch(deleteRequestIdFromStore({ requestId }));
            }
        }
        res.config.requestIds = [];
    };

    const deleteSuccess = (res: AxiosResponse) => {
        deleteRequestId(res);
        return res;
    };

    const failure = (res: AxiosResponse) => {
        deleteRequestId(res);
        if (res?.status === 401) {
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
            // window.location.reload();
            // const history = useHistory();
            //
        }
        return Promise.reject(res);
    };

    return { addRequestId, deleteSuccess, failure };
};

const axiosInstance = axios.create({
    baseURL: baseURL(Environment),
    timeout: 40000,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    }
});

axiosInstance.interceptors.request.use(loadingHandler().addRequestId, undefined);
axiosInstance.interceptors.response.use(resStatusHandler(), errStatusHandler());
axiosInstance.interceptors.response.use(loadingHandler().deleteSuccess, loadingHandler().failure);

const CancelToken = axios.CancelToken;

export default axiosInstance;
export type { AxiosResponse };
export { CancelToken };
