export enum STATUS_CODE_RESPONSE {
    SUCCESS = 200,
    BAD_REQUEST = 400,

    PERMISSION = 522,
    SERVER_ERROR = 500,

    // Token
    TOKEN_EXPIRED = 401,
    TOKEN_NOT_FOUND = 402,
    TOKEN_INVALID = 403,
    TOKEN_BLACKLIST = 405,

    LOGIN_FAIL = 4000,
    REGISTER_ERROR = 4001,
    NFC_WRITE_ERROR = 7000,
    GLOBAL_ERROR = 4000,

    // Feedback
    FEEDBACK_ERROR = 9000,

    PROFILE_BLANK = 5001,
    PHONE_EXISTS = 5002,
    PASSWORD_NOT_MATCH = 5003,
    PASSWORD_SAME = 5004,

    REGISTER_SOCIAL = 9000,
    SOCIAL_DISCONNECT_FAIL = 9001,
    SOCIAL_CONNECT_FAIL = 9002,
    SOCIAL_CONNECT_EXISTS = 9003
}
