// import { combineReducers } from '@reduxjs/toolkit';
import { loadReducers } from './loader';
// import diceHistoryReducer from './dice-history/slice';

const reducers = loadReducers();

// const combine = combineReducers(
//     diceHistoryReducer,
//     diceHistoryReducer,
//     diceHistoryReducer,
//     diceHistoryReducer,
//     diceHistoryReducer,
//     diceHistoryReducer,
//     diceHistoryReducer,
//     diceHistoryReducer,
//     diceHistoryReducer,
//     diceHistoryReducer,
//     diceHistoryReducer,
// );

export default reducers;
