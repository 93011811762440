import { STATUS_CODE_RESPONSE } from 'common/api-models';
import { setResponseStatus } from 'ducks/response-status/slice';
import { store } from 'ducks/store';

/**
 * Handle response error
 * @returns  Rejected
 */

export const errStatusHandler = () => (err: any) => {
    if (err.code === 'ECONNABORTED') {
        store.dispatch(
            setResponseStatus({
                requestId: err.config.url ?? '',
                code: err.code,
                success: false,
                message: 'Không thể lấy dữ liệu!'
            })
        );

        return Promise.reject(err);
    }

    if (err.message === 'Network Error') {
        store.dispatch(
            setResponseStatus({
                requestId: err.config.url ?? '',
                code: 0,
                success: false,
                message: 'Không thể kết nối với máy chủ!'
            })
        );

        return Promise.reject(err);
    }

    if (err.code === 404) {
        store.dispatch(
            setResponseStatus({
                requestId: err.config.url ?? '',
                code: 404,
                success: false,
                message: 'Trang không tồn tại!'
            })
        );

        return Promise.reject(err);
    }

    if (err.code === 500 || err.response?.data?.err === -2000) {
        store.dispatch(
            setResponseStatus({
                requestId: err.config.url ?? '',
                code: 500,
                success: false,
                message: 'Mật khẩu hoặc tài khoản không đúng!'
            })
        );

        return Promise.reject(err);
    }

    if (err.code === 500 || err.response.status === 500) {
        store.dispatch(
            setResponseStatus({
                requestId: err.config.url ?? '',
                code: 500,
                success: false,
                message: 'Lỗi máy chủ!'
            })
        );

        return Promise.reject(err);
    }

    if (err.response.status === STATUS_CODE_RESPONSE.TOKEN_EXPIRED) {
        store.dispatch(
            setResponseStatus({
                requestId: err.config.url ?? '',
                code: STATUS_CODE_RESPONSE.TOKEN_EXPIRED,
                success: false,
                message: err.response.data?.msg ?? 'Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!'
            })
        );
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        // store.dispatch(loginFailure(err));

        return Promise.reject(err);
    }

    if (err.response.status === STATUS_CODE_RESPONSE.TOKEN_INVALID) {
        store.dispatch(
            setResponseStatus({
                requestId: err.config.url ?? '',
                code: STATUS_CODE_RESPONSE.TOKEN_EXPIRED,
                success: false,
                message: 'Bạn không phải là quản trị viên, vui lòng quay lại!'
            })
        );

        return Promise.reject(err);
    }

    if (err.response.status === STATUS_CODE_RESPONSE.TOKEN_NOT_FOUND) {
        store.dispatch(
            setResponseStatus({
                requestId: err.config.url ?? '',
                code: STATUS_CODE_RESPONSE.TOKEN_NOT_FOUND,
                success: false,
                message: 'Phiên đăng nhập không tồn tại, vui lòng đăng nhập lại!'
            })
        );

        return Promise.reject(err);
    }

    if (err.response.status === 404) {
        store.dispatch(
            setResponseStatus({
                requestId: err.config.url ?? '',
                code: 0,
                success: false,
                message: '404 Not found!'
            })
        );

        return Promise.reject(err);
    }

    if (err.response.status) {
        store.dispatch(
            setResponseStatus({
                requestId: err.config.url ?? '',
                code: err.response.status,
                success: false,
                message: err.response.data?.message ?? err.response.data?.msg ?? 'Lỗi không xác định!'
            })
        );

        return Promise.reject(err);
    }

    if (!err.code) {
        store.dispatch(
            setResponseStatus({
                requestId: err.config.url ?? '',
                code: 0,
                success: false,
                message: 'Có lỗi phát sinh khi kết nối tới máy chủ!'
            })
        );

        return Promise.reject(err);
    }

    return Promise.reject(err);
};

export const resStatusHandler = () => (res: any) => {
    if (res.data?.status === 'failure' || (res.data?.err && res.data?.err !== 0)) {
        store.dispatch(
            setResponseStatus({
                requestId: res.config.url ?? '',
                code: res.data?.err,
                success: false,
                message: res.data?.message ?? res.data?.msg
            })
        );
        return res;
    }

    if (res.data?.status === 'success' || res.data?.message || res.data?.msg || res.data?.err === 0) {
        store.dispatch(
            setResponseStatus({
                requestId: res.config.url ?? '',
                code: 0,
                success: true,
                message: res.data?.message ?? res.data?.msg
            })
        );
    }

    return res;
};
