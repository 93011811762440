// project imports
// eslint-disable-next-line
import { Outlet } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import { useSelector } from "ducks/store";

// ==============================|| MINIMAL LAYOUT ||============================== //
interface LayoutMinimalProps {
  children?: React.ReactNode;
}

const MinimalLayout = ({ children }: LayoutMinimalProps) => {
  const loadingItems = useSelector((state) => state.loading.loadingItems);

  const showLoading = () =>
    loadingItems.length > 0 &&
    loadingItems.every((loadingItem) => loadingItem.showLoading);

  return (
    <>
      <Outlet />
      {/* <Customization /> */}
      {children}
      <LoadingOverlay
        active={showLoading()}
        spinner
        text="Loading your content..."
      ></LoadingOverlay>
    </>
  );
};

export default MinimalLayout;
