import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DefaultRootStateProps } from 'ducks/types';
import { initialUser, UserAuthReq, User } from './types';

const initialState: DefaultRootStateProps['user'] = {
    accessToken: initialUser.accessToken
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        loginUserRequest(state: User, _action: PayloadAction<UserAuthReq>) {
            state.accessToken = initialUser.accessToken;
        },
        loginUserSuccess(state: User, action: PayloadAction<User>) {
            state.accessToken = action.payload.accessToken;
        },
        loginUserFailure(_state: User, _action: PayloadAction<any>) {},
    }
});

const userReducer = userSlice.reducer;

export default userReducer;

export const { loginUserRequest, loginUserSuccess, loginUserFailure } = userSlice.actions;

export const userInitialState = userSlice.getInitialState();
