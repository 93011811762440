import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DefaultRootStateProps } from 'ducks/types';
import { initialDiceHistoryResponse, DiceHistoryState, DiceHistoryRequest } from './types';

const initialState: DefaultRootStateProps['diceHistory'] = initialDiceHistoryResponse;

const diceHistorySlice = createSlice({
    name: 'diceHistory',
    initialState,
    reducers: {
        getDiceHistoryRequest(_state: DiceHistoryState, _action: PayloadAction<DiceHistoryRequest>) {
            return initialDiceHistoryResponse;
        },
        getDiceHistorySuccess(_state: DiceHistoryState, action: PayloadAction<DiceHistoryState>) {
            return action.payload;
        },
        getDiceHistoryFailure(_state: DiceHistoryState, _action: PayloadAction<any>) {},
    }
});

const diceHistoryReducer = diceHistorySlice.reducer;

export default diceHistoryReducer;

export const { getDiceHistoryRequest, getDiceHistorySuccess, getDiceHistoryFailure } = diceHistorySlice.actions;

export const diceHistoryInitialState = diceHistorySlice.getInitialState();
