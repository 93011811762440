import { put, call, takeLatest } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import { getType } from "@reduxjs/toolkit";
import axiosClient, { AxiosResponse } from "ducks/axios-instance";
import { loginUserRequest, loginUserSuccess, loginUserFailure } from "./slice";
import { User } from "./types";

export function* LoginUserRequest(
  action: ReturnType<typeof loginUserRequest>
): SagaIterator {
  try {
    const response: AxiosResponse<User> = yield call(
      axiosClient.post,
      "/auth/login",
      action.payload
    );
    yield put(loginUserSuccess(response.data));
  } catch (err: any) {
    yield put(loginUserFailure(err));
  }
}

export default function* defaultGetStationerySaga() {
  yield takeLatest(getType(loginUserRequest), LoginUserRequest);
}
