import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DefaultRootStateProps } from 'ducks/types';
import { LoadingItem } from './types';

const initialState: DefaultRootStateProps['loading'] = {
    loadingItems: []
};

const loadingSlice = createSlice({
    name: 'loading',
    initialState,
    reducers: {
        addRequestId(state: any, action: PayloadAction<LoadingItem>) {
            state.loadingItems.push(action.payload);
        },
        deleteRequestId(state: any, action: PayloadAction<LoadingItem>) {
            state.loadingItems = state.loadingItems.filter(
                (loadingItem: LoadingItem) => loadingItem.requestId !== action.payload.requestId
            );
        }
    }
});

const loadingReducer = loadingSlice.reducer;

export default loadingReducer;

export const { addRequestId, deleteRequestId } = loadingSlice.actions;

export const loadingInitialState = loadingSlice.getInitialState();
