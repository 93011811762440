import { getDiceHistoryRequest } from "ducks/dice-history/slice";
import { useDispatch, useSelector } from "ducks/store";
import React, { useEffect, useState } from "react";

const Home = () => {
  const dispatch = useDispatch();

  const [bridgeLength, setBridgeLength] = useState(2);
  const [date, setDate] = useState<string | undefined>("sap");
  const [reverse, setReverse] = useState(false);

  const { win, lose, history } = useSelector((state) => state.diceHistory);

  const onChangeBridgeLength = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBridgeLength(Number(event.target.value));
  };

  const onChangeDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDate(event.target.value);
  };

  const onChangeReverse = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReverse(event.target.checked);
  };

  useEffect(() => {
    dispatch(
      getDiceHistoryRequest({
        isFollowBridge: true,
        bridgeLength,
        reverse,
        date,
      })
    );
  }, [dispatch, bridgeLength, date, reverse]);

  return (
    <div className="flex justify-center items-center">
      <section className="nes-container with-title is-dark w-fit">
        <h3 className="title">Home</h3>
        <div>
          <label htmlFor="dark_field">Example</label>
          <input
            value={bridgeLength}
            onChange={onChangeBridgeLength}
            type="text"
            id="dark_field"
            className="nes-input is-dark"
            placeholder="bridgeLength"
          />
        </div>
        <div>
          <label htmlFor="date_field">Text</label>
          <input
            value={date}
            onChange={onChangeDate}
            type="text"
            id="date_field"
            className="nes-input is-dark"
            placeholder="Date"
          />
        </div>
        <div className="p-2">
          <label>
            <input
              type="checkbox"
              className="nes-checkbox is-dark"
              checked={reverse}
              onChange={onChangeReverse}
            />
            <span>Reverse</span>
          </label>
        </div>
        <div className="p-2">
          <label>
            WIN:{win} | LOSE:{lose}
          </label>
        </div>
        <ul className="icon-list">
          {[...history].reverse().map((dice) => (
            <li className="w-full">
              <a href="/" className="nes-badge is-splited w-full">
                {dice.result || dice.result === 0 ? (
                  <span
                    className={
                      dice.predict === dice.result ? "is-success" : "is-error"
                    }
                  >
                    {dice.current > dice.result ? "*U*U*" : "*O*O*"}
                  </span>
                ) : (
                  <span className="is-warning">
                    {dice.predict > dice.current ? "*O*O*" : "*U*U*"}
                  </span>
                )}
                <span
                  className={
                    dice.predict === dice.result ? "is-success" : "is-error"
                  }
                >
                  {`${dice.current}|${dice.predict}|${dice.result}`}
                </span>
              </a>
            </li>
          ))}
        </ul>
      </section>
    </div>
  );
};

export default Home;
