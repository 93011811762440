export type DiceHistoryRequest = {
  isFollowBridge: boolean;
  bridgeLength: number;
  reverse: boolean;
  date?: string;
};

export type DiceHistoryResponse = {
  success: boolean;
  statusCode: number;
  message: string;
  data: DiceHistoryState;
};

export type DiceHistoryState = {
  win: number;
  lose: number;
  history: DiceHistory[];
};

export type DiceHistory = {
  current: number;
  predict: number;
  predictType: number;
  result: number;
  date: string;
  resultType: number;
};

export const initialDiceHistoryResponse = {
  win: 0,
  lose: 0,
  history: [],
};
