/* eslint-disable react-hooks/exhaustive-deps */
import useAuth from "hooks/useAuth";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GuardProps } from "./AuthGuard";

// ==============================|| GUEST GUARD ||============================== //

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */

const GuestGuard = ({ children }: GuardProps) => {
  const authData = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (authData) {
      if (window.history.state && window.history.length) {
        navigate(-1);
      } else {
        navigate("/home", { replace: true });
      }
    }
  }, [authData, navigate]);

  return children;
};

export default GuestGuard;
