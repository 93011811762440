import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DefaultRootStateProps } from 'ducks/types';
import { ResponseStatusState } from './types';

const initialState: DefaultRootStateProps['responseStatus'] = {
    requestId: '',
    code: 0,
    success: false,
    message: ''
};

const responseStatusSlice = createSlice({
    name: 'responseStatus',
    initialState,
    reducers: {
        setResponseStatus(state: any, action: PayloadAction<ResponseStatusState>) {
            return action.payload;
        }
    }
});

const responseStatusReducer = responseStatusSlice.reducer;

export default responseStatusReducer;

export const { setResponseStatus } = responseStatusSlice.actions;

export const responseStatusInitialState = responseStatusSlice.getInitialState();
