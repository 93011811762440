import React from "react";
import { Controller, useFormContext } from "react-hook-form";

export interface NESInputProps {
  name: string;
  type: "text" | "password";
  placeholder?: string;
  onChange?: (
    data: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  onBlur?: (
    data: React.FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
}

const NESInput = ({
  name,
  type,
  placeholder,
  onChange,
  onBlur,
}: NESInputProps) => {
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <input
          //   helperText={errors?.[`${name}`]?.message}
          type={type}
          id="username"
          className={`nes-input text-black ${
            errors?.[`${name}`] && "is-error"
          }`}
          placeholder={placeholder}
          {...field}
          {...register(name, { onChange, onBlur })}
        />
      )}
    />
  );
};

export default NESInput;
