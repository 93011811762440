import { useNavigate } from "react-router-dom";

// project imports
import useAuth from "hooks/useAuth";
import { useEffect } from "react";

// ==============================|| AUTH GUARD ||============================== //

export interface GuardProps {
  children: any;
}

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }: GuardProps) => {
  const authData = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!authData) {
      navigate("/login");
    }
  }, [authData, navigate]);

  return children;
};

export default AuthGuard;
