// project imports

import MinimalLayout from "layouts/minimal-layout";
import AuthGuard from "./route-guard/AuthGuard";
import Home from "pages/home";

// ==============================|| AUTH ROUTING ||============================== //

const HomeRoutes = {
  path: "/",
  element: (
    <AuthGuard>
      <MinimalLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "/",
      element: <Home />,
    },
  ],
};

export default HomeRoutes;
